<template>
  <div>
    <div v-if="user" id="database">
      <nav class="navbar sticky-top navbar-light bg-light">
        <div class="input-group my-2">
          <!-- Search UI Group -->
          <div class="row mr-0">
            <div class="selection-group-btn">
              <button
                type="button"
                class="ml-3 btn btn-info dropdown-toggle"
                data-flip="false"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <font-awesome-icon icon="hand-pointer" />
                <span class="caret"></span>
              </button>
              <div class="dropdown-menu">
                <a
                  class="dropdown-item"
                  href="#"
                  v-on:click.prevent="requestTypeChange('bioreactor')"
                >
                  Bioreactor
                  <span v-if="selectedType === 'bioreactor'">&#x2713;</span>
                </a>
                <a
                  class="dropdown-item"
                  href="#"
                  v-on:click.prevent="requestTypeChange('branch')"
                >
                  Branch
                  <span v-if="selectedType === 'branch'">&#x2713;</span>
                </a>
                <a
                  class="dropdown-item"
                  href="#"
                  v-on:click.prevent="requestTypeChange('chemical')"
                >
                  Chemical
                  <span v-if="selectedType === 'chemical'">&#x2713;</span>
                </a>
                <a
                  class="dropdown-item"
                  href="#"
                  v-on:click.prevent="requestTypeChange('culture')"
                >
                  Culture
                  <span v-if="selectedType === 'culture'">&#x2713;</span>
                </a>
                <a
                  class="dropdown-item"
                  href="#"
                  v-on:click.prevent="requestTypeChange('experiment')"
                >
                  Experiment
                  <span v-if="selectedType === 'experiment'">&#x2713;</span>
                </a>
                <a
                  class="dropdown-item"
                  href="#"
                  v-on:click.prevent="requestTypeChange('flask')"
                >
                  Flask
                  <span v-if="selectedType === 'flask'">&#x2713;</span>
                </a>
                <a
                  class="dropdown-item"
                  href="#"
                  v-on:click.prevent="requestTypeChange('incubator')"
                >
                  Incubator
                  <span v-if="selectedType === 'incubator'">&#x2713;</span>
                </a>
                <a
                  class="dropdown-item"
                  href="#"
                  v-on:click.prevent="requestTypeChange('manufacturer')"
                >
                  Manufacturer
                  <span v-if="selectedType === 'manufacturer'">&#x2713;</span>
                </a>
                <a
                  class="dropdown-item"
                  href="#"
                  v-on:click.prevent="requestTypeChange('media')"
                >
                  Media
                  <span v-if="selectedType === 'media'">&#x2713;</span>
                </a>
                <a
                  class="dropdown-item"
                  href="#"
                  v-on:click.prevent="requestTypeChange('multiwell')"
                >
                  Multiwell
                  <span v-if="selectedType === 'multiwell'">&#x2713;</span>
                </a>
                <a
                  class="dropdown-item"
                  href="#"
                  v-on:click.prevent="requestTypeChange('plate')"
                >
                  Plate
                  <span v-if="selectedType === 'plate'">&#x2713;</span>
                </a>
                <a
                  class="dropdown-item"
                  href="#"
                  v-on:click.prevent="requestTypeChange('project')"
                >
                  Project
                  <span v-if="selectedType === 'project'">&#x2713;</span>
                </a>
                <a
                  class="dropdown-item"
                  href="#"
                  v-on:click.prevent="requestTypeChange('recipe')"
                >
                  Recipe
                  <span v-if="selectedType === 'recipe'">&#x2713;</span>
                </a>
                <a
                  class="dropdown-item"
                  href="#"
                  v-on:click.prevent="requestTypeChange('strain')"
                >
                  Strain
                  <span v-if="selectedType === 'strain'">&#x2713;</span>
                </a>
                <a
                  class="dropdown-item"
                  href="#"
                  v-on:click.prevent="requestTypeChange('tube')"
                >
                  Tube
                  <span v-if="selectedType === 'tube'">&#x2713;</span>
                </a>
                <!-- Only admin is allowed to access the user list -->
                <a
                  v-if="user.role === 'admin'"
                  class="dropdown-item"
                  href="#"
                  v-on:click.prevent="requestTypeChange('user')"
                >
                  User
                  <span v-if="selectedType === 'user'">&#x2713;</span>
                </a>
              </div>
              <!-- .dropdown-menu -->
            </div>
            <!-- .selection-group-btn -->

            <!-- Note: Use user's role to control access (user.role === 'admin' || user.role === 'lead') -->
            <div v-if="canAddItem" class="add-btn ml-1">
              <button
                type="button"
                class="btn btn-info"
                data-toggle="modal"
                data-target="#addNewItem"
                v-on:click="prepAddItem(item)"
                data-backdrop="static"
                data-keyboard="false"
              >
                <font-awesome-icon icon="plus" />
              </button>
            </div>
          </div>
          <input
            id="searchTerm"
            placeholder="Search"
            type="text"
            class="form-control ml-1"
            aria-label="Search"
            v-model="searchTerm"
          />

          <div class="search-group-btn">
            <button
              type="button"
              class="ml-1 btn btn-info dropdown-toggle"
              data-flip="false"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <font-awesome-icon icon="search" />
              <span class="small mt-2 ml-1">{{ searchFilterKey }}</span>
              <span class="caret"></span>
            </button>

            <div class="dropdown-menu dropdown-menu-right">
              <a
                class="dropdown-item"
                href="#"
                id="merge"
                v-on:click.prevent="requestKeyChange('merge')"
              >
                Merge
                <span v-if="filterKey === 'merge'">&#x2713;</span>
              </a>

              <a
                class="dropdown-item"
                href="#"
                id="olab_id"
                v-on:click.prevent="requestKeyChange('olab_id')"
              >
                OLAB ID
                <span v-if="filterKey === 'olab_id'">&#x2713;</span>
              </a>

              <a
                class="dropdown-item"
                href="#"
                id="name"
                v-on:click.prevent="requestKeyChange('name')"
              >
                Name
                <span v-if="filterKey === 'name'">&#x2713;</span>
              </a>

              <a
                class="dropdown-item"
                href="#"
                id="creator"
                v-on:click.prevent="requestKeyChange('creator')"
              >
                Creator
                <span v-if="filterKey === 'creator'">&#x2713;</span>
              </a>
            </div>
            <!-- .dropdown-menu -->
          </div>
          <!-- search-group-btn -->
        </div>
        <!-- input-group my-2 -->

        <div class="navbar-brand text-truncate">
          <!-- Placeholder for user profile-->
          <button
            type="button"
            class="btn btn-outline-danger ml-1"
            data-toggle="modal"
            data-target="#userProfile"
            data-backdrop="static"
            data-keyboard="false"
          >
            <font-awesome-icon icon="id-badge" />
          </button>
          <button
            type="button"
            class="btn btn-outline-success ml-1"
            data-toggle="modal"
            data-target="#infoProfile"
            data-backdrop="static"
            data-keyboard="false"
          >
            <font-awesome-icon icon="info" />
          </button>
          <span
            v-if="statusObj.searching"
            class="mx-2 spinner-border spinner-border-sm text-info"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </span>
          <span class="h4 text-success text-capitalize ml-2"
            >{{ selectedTypeName }} Database System</span
          >
        </div>
        <!-- navbar-brand text-truncate -->
      </nav>
      <div>
        <OlabStatusObjAlerts
          :statusObj="statusObj"
          @emitStatusMessage="emitStatusMessage"
          @emitErrorMessage="emitErrorMessage"
        />
      </div>
      <!-- sticky-top -->

      <!-- userProfile -->
      <OlabUserProfile :user="user" />

      <!-- infoProfile -->
      <OlabInfoProfile :user="user" :selectedTypeName="selectedTypeName" />

      <!-- addNewItem -->
      <AddForm
        :user="user"
        :dbInfo="dbInfo"
        :selectedType="selectedType"
        :selectedTypeName="selectedTypeName"
        :item="item"
        :selectedItems="selected.items"
        :statusObj="statusObj"
      />
      <!-- .modal fade addNewItem -->

      <div>
        <DatabaseList
          v-if="user"
          :user="user"
          :dbInfo="dbInfo"
          :selectedType="selectedType"
          :item="item"
          :selectedItems="selected.items"
          :statusObj="statusObj"
        />
      </div>
      <div class="my-5">
        <nav aria-label="Page navigation">
          <ul class="pagination justify-content-center">
            <li class="page-item">
              <button
                type="button"
                v-bind:disabled="selected.prevIsDisabled == true"
                class="btn btn-primary"
                v-on:click="handlePrev()"
              >
                &lt;
              </button>
            </li>
            <li class="mx-3">{{ selected.pageNumber }}</li>
            <li class="page-item">
              <button
                type="button"
                v-bind:disabled="selected.nextIsDisabled == true"
                class="btn btn-primary"
                v-on:click="handleNext()"
              >
                &gt;
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div v-else class="mt-5">
      <div class="text-secondary text-center">
        <div class="h2">Inactivity Time Out</div>
        <router-link
          class="btn btn-outline-primary mt-2"
          to="/login"
          v-if="!user"
          >Log in</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import DatabaseList from "@/components/DatabaseList";
import OlabUserProfile from "@/components/OlabUserProfile";
import OlabInfoProfile from "@/components/OlabInfoProfile";
import OlabStatusObjAlerts from "@/components/OlabStatusObjAlerts.vue";
import AddForm from "@/components/forms/AddForm.vue";
import { OlabAxios } from "../olab/olabaxios.js";
import { OlabItem } from "../olab/olabitem.js";
import { OlabUtils } from "../olab/olabutils.js";
import { OlabSearch } from "../olab/olabsearch.js";

export default {
  name: "OlabDatabase",
  data() {
    return {
      title: "Database",
      searchTerm: "",
      filterKey: "merge",
      filterDir: "asc",
      selectedType: "project",
      lastSelectedType: null,
      selectedTypeName: "Project",
      item: new OlabItem(),
      invalidFB: OlabItem.getInvalidFeedbacks(),
      includeDeleted: true,
      selected: {
        items: null,
        sortBy: "olab_id",
        pageNumber: 1,
        nextIsDisabled: true,
        prevIsDisabled: true
      },
      throttleTimeout: null // To store the setTimeout ID
    };
  },
  components: {
    FontAwesomeIcon,
    DatabaseList,
    OlabUserProfile,
    OlabInfoProfile,
    OlabStatusObjAlerts,
    AddForm
  },
  props: ["user", "dbInfo", "statusObj"],
  emits: ["setStatusMessage", "setErrorMessage"],
  async mounted() {
    // console.log("Database mounted ...");
    // Reset status and error
    this.emitStatusMessage(null, false);
    this.emitErrorMessage(null);
    try {
      const searchBody = OlabSearch.createSearchBody(
        this.selectedType,
        "",
        "olab_id"
      );
      const items = await OlabAxios.getProjects(searchBody, this.statusObj);

      if (items.length >= OlabAxios.LIMIT_PER_PAGE) {
        this.selected.sortBy = "olab_id";
        this.selected.nextIsDisabled = false;
      }
      this.selected.items = items;
    } catch (err) {
      console.log(err);
      this.emitErrorMessage(OlabUtils.getErrorMessage(err));
    }
  },
  computed: {
    searchFilterKey() {
      let key = "Merge";
      switch (this.filterKey) {
        case "merge":
          key = "Merge";
          break;
        case "olab_id":
          key = "OLAB ID";
          break;
        case "name":
          key = "Name";
          break;
        case "creator":
          key = "Creator";
          break;
      }
      return key;
    },
    canAddItem() {
      let result = false;
      // Note: Can use user's role to control access
      //     (user.role === 'admin' || user.role === 'lead')
      result =
        this.selectedType !== "culture" &&
        this.selectedType !== "experiment" &&
        ((this.selectedType === "user" && this.user.role === "admin") ||
          (this.selectedType !== "user" &&
            (this.user.role === "admin" || this.user.role === "lead")));
      // console.log("canAddItem =", result);
      return result;
    }
  },
  methods: {
    emitStatusMessage(statusMsg, statusProgress) {
      this.$emit("setStatusMessage", statusMsg, statusProgress);
    },
    emitErrorMessage(errMsg) {
      this.$emit("setErrorMessage", errMsg);
    },
    handlePrev() {
      let sType = this.selectedType;
      // console.log("handlePrev ... sType = " + sType);
      OlabSearch.doPrev(
        sType,
        this.searchTerm,
        this.includeDeleted,
        this.selected,
        this.statusObj
      );
    },
    handleNext() {
      let sType = this.selectedType;
      // console.log("handleNext ... sType = " + sType);
      OlabSearch.doNext(
        sType,
        this.searchTerm,
        this.includeDeleted,
        this.selected,
        this.statusObj
      );
    },
    requestTypeChange(value) {
      this.selectedType = value;
      // console.log("requestTypeChange " + this.selectedType);
      if (this.selectedType !== this.lastSelectedType) {
        // Reset searchTerm when type changed
        this.searchTerm = "";

        // console.log("Watch - selectedType = " + this.selectedType);

        switch (this.selectedType) {
          case "bioreactor":
            this.selectedTypeName = "Bioreactor";
            this.searchTerm = "";
            this.doSearchTerm(this.searchTerm, this.selectedType);
            break;
          case "branch":
            this.selectedTypeName = "Branch";
            this.searchTerm = "";
            this.doSearchTerm(this.searchTerm, this.selectedType);
            break;
          case "chemical":
            this.selectedTypeName = "Chemical";
            this.searchTerm = "";
            this.doSearchTerm(this.searchTerm, this.selectedType);
            break;
          case "culture":
            this.selectedTypeName = "Culture";
            this.searchTerm = "";
            this.doSearchTerm(this.searchTerm, this.selectedType);
            break;
          case "experiment":
            this.selectedTypeName = "Experiment";
            this.searchTerm = "";
            this.doSearchTerm(this.searchTerm, this.selectedType);
            break;
          case "flask":
            this.selectedTypeName = "Flask";
            this.searchTerm = "";
            this.doSearchTerm(this.searchTerm, this.selectedType);
            break;
          case "incubator":
            this.selectedTypeName = "Incubator";
            this.searchTerm = "";
            this.doSearchTerm(this.searchTerm, this.selectedType);
            break;
          case "manufacturer":
            this.selectedTypeName = "Manufacturer";
            this.searchTerm = "";
            this.doSearchTerm(this.searchTerm, this.selectedType);
            break;
          case "media":
            this.selectedTypeName = "Media";
            this.searchTerm = "";
            this.doSearchTerm(this.searchTerm, this.selectedType);
            break;
          case "multiwell":
            this.selectedTypeName = "Multiwell";
            this.searchTerm = "";
            this.doSearchTerm(this.searchTerm, this.selectedType);
            break;
          case "plate":
            this.selectedTypeName = "Plate";
            this.searchTerm = "";
            this.doSearchTerm(this.searchTerm, this.selectedType);
            break;
          case "project":
            this.selectedTypeName = "Project";
            this.searchTerm = "";
            this.doSearchTerm(this.searchTerm, this.selectedType);
            break;
          case "recipe":
            this.selectedTypeName = "Recipe";
            this.searchTerm = "";
            this.doSearchTerm(this.searchTerm, this.selectedType);
            break;
          case "strain":
            this.selectedTypeName = "Strain";
            this.searchTerm = "";
            this.doSearchTerm(this.searchTerm, this.selectedType);
            break;
          case "tube":
            this.selectedTypeName = "Tube";
            this.searchTerm = "";
            this.doSearchTerm(this.searchTerm, this.selectedType);
            break;
          case "user":
            this.selectedTypeName = "User";
            this.searchTerm = "";
            this.doSearchTerm(this.searchTerm, this.selectedType);
            break;
        }
        this.lastSelectedType = this.selectedType;
      }
    },
    requestKeyChange(value) {
      this.filterKey = value;
      this.doSearchTerm(this.searchTerm, this.selectedType);
    },
    prepAddItem(item) {
      // console.log("prepAddItem");
      item.initItem();
      // Do item preconfiguration here if needed
      // Register item is created by user
      item.creator = this.user.email;
      if (this.selectedType === "recipe" || this.selectedType === "chemical") {
        item.unit = "L";
      }
    },
    doSearchTerm(sTerm, sType) {
      // console.log(
      //   "doSearchTerm - sTerm = " +
      //     sTerm +
      //     ", sType = " +
      //     sType +
      //     ", filterKey = " +
      //     this.filterKey
      // );

      if (this.statusObj.progress === true) {
        // console.log("In progress ... Try again later");
        // Clear any existing timeout
        clearTimeout(this.throttleTimeout);
        this.throttleTimeout = setTimeout(() => {
          this.doSearchTerm(sTerm, sType);
        }, 2000); // 2 second throttle
      } else {
        OlabSearch.doSearchTerm(
          sTerm,
          sType,
          this.includeDeleted,
          this.filterKey,
          this.selected,
          this.statusObj
        );
      }
    }
  },
  watch: {
    searchTerm() {
      // console.log("Watch - searchTerm = " + this.searchTerm);
      this.doSearchTerm(this.searchTerm, this.selectedType);
    }
  }
};
</script>
